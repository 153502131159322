import React from 'react';
import Modal from '../../../components/Modal';
import img from '../../../images/city/birmingham_header.jpg';
import graphic from '../../../images/graphics/modal-birmingham.jpg';
import report from '../../../report/report.pdf';

const media = {
  secTitle: 'Birmingham, AL',
  cityModal: true,
  type: 'image',
  image: {
    src: img,
  },
};

export default () => (
  <Modal closeTo="Been" media={media}>
    <p>
      Birmingham is located in the north central region of Alabama and is an
      important hub within the Deep South, Piedmont and Appalachian regions of
      the nation. It developed as an industrial center, based on mining, the new
      iron and steel industry, and rail transport. Birmingham gained national
      and international attention as a center of activity during the Civil
      Rights Movement. Today, banking and healthcare are major economic drivers.
    </p>
    <p>
      Today in Birmingham, 75.4 percent of the population is people of color30.
      Racial economic gaps are wide and persistent. The median hourly wage for
      white workers is $20 while people of color only earn $14. Birmingham is
      facing a serious skills gap. While 37 percent of jobs require some college
      education, only 23 percent of black residents are prepared to compete for
      those jobs. In Birmingham, we hosted three focus groups to hear the
      opinions of non-college-educated black and white residents and
      college-educated black residents. Learn more by reading the{' '}
      <a
        target="_blank"
        rel="noopener noreferrer"
        className="report-link"
        href={report}
      >
        full report.
      </a>
    </p>
    <img
      src={graphic}
      alt="Bar chart and map of racial distribution in Birmingham, AL"
      className="Img-responsive"
    />
  </Modal>
);
